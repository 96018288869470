<template>
  <div>
    <b-card class="shadow m-3 p-3 w-75">
      <b-row></b-row>
      <h5>
        <b-icon icon="people"></b-icon>
        确定接受人：
      </h5>
      <small>根据Id查找</small>
      <b-row class="px-3">
        <b-form-input class="w-75" v-model="receiverId" placeholder="输入职员ID"></b-form-input>
        <b-button class="w-25" @click="showStaff">选择此人</b-button>
        <p v-if="staff.name!==''">姓名：{{staff.name}}</p>
      </b-row>
      <el-divider></el-divider>
      <b-row>
        <h5>
          <b-icon icon="pencil"></b-icon>
          编辑内容：
        </h5>
        <div v-if="show" class="mt-3">
          <b-row class="my-3">
            <b-col :md="7">
              <b-form-group
                  id="input-group-1"
                  label="信息类型"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.type"
                    type="text"
                    placeholder="Enter Type"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col :md="5">
              <b-form-group id="input-group-2" label="信息级别" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="form.level"
                    placeholder="Enter Level"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col :md="4">
              <b-form-group>
                <label for="example-datepicker">信息有效期</label>
                <b-form-datepicker id="example-datepicker" v-model="form.validity" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col :md="4">
              <b-form-group id="input-group-2" label="信息格式" label-for="input-2">
                <b-form-input
                    id="input-3"
                    v-model="form.format"
                    placeholder="Enter Format"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col :md="4">
              <b-form-group id="input-group-2" label="信息来源" label-for="input-2">
                <b-form-input
                    id="input-4"
                    v-model="form.source"
                    placeholder="Enter source"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
              class="my-3"
              id="input-group-3"
              label="信息内容"
              label-for="input-1"
              description="从此处编辑信息内容."
          >
            <b-form-textarea
                id="textarea"
                v-model="form.content"
                placeholder="Enter Content..."
                rows="3"
                max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <div style="text-align: center;" :disabled="flag">
            <b-button class="w-50  mt-3" variant="primary" @click="sendMessage">Submit</b-button>
          </div>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {sendMessage, showStaffById} from "@/api";

export default {
  name: "CreateMassage",
  data() {
    return {
      flag:'true',
      form: {
        type: '',
        level: '',
        validity: '',
        format: '',
        source: '',
        content: ''
      },
      staff: {},
      receiverId: '',
      foods: [{text: 'Select One', value: null}, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      show: true
    }
  },
  methods: {
    sendMessage() {
      sendMessage(
          this.form.type, this.form.level, this.form.content, this.form.validity + ' 00:00:00', this.form.format, this.form.source, this.receiverId
      ).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    showStaff() {
      showStaffById(this.receiverId).then(res => {
        if (res.data.status) {
          this.staff = res.data.data
          if (this.staff===null){
            this.$message.error('查无此人')
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
