<template>
  <div>
    <b-container fluid class="p-5">
      <b-tabs content-class="mt-3">
        <b-tab title="查看消息" active>
          <ScanMessage></ScanMessage>
        </b-tab>
        <b-tab title="已发消息">
          <OutBox></OutBox>
        </b-tab>
        <b-tab title="创建消息">
          <CreateMassage></CreateMassage>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import ScanMessage from "@/components/Messgae/components/ScanMessage";
import CreateMassage from "@/components/Messgae/components/CreateMassage";
import OutBox from "@/components/Messgae/components/OutBox";

export default {
  name: "Message",
  components: {OutBox, CreateMassage, ScanMessage}
}
</script>

<style scoped>

</style>
